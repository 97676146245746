import React from 'react';

export function GLP1Hero() {
  return (
    <div className={'glp1-hero-outer-container'}>
      <h2 style={{color: '#FB9C28', marginBottom: '30px'}}>Is GLP-1 Right for You?</h2>
      <div className={'glp1-hero-container'}>
        <div className={'glp1-hero-left'}>
          <p>
            With a partnership between CleverRX and LinkScripts, our clients are now able to discover new tools to aid
            in their weight loss journey. Please visit LinkScripts to see if incorporating GLP-1 into your lifestyle
            will lead to improved weight loss.
          </p>
          <a
            style={{
              backgroundColor: '#3DB9D2',
              color: 'white',
              borderRadius: '20px',
              padding: '10px',
              display: 'block',
              width: '100%',
              textAlign: 'center',
              marginTop: '20px',
            }}
            href={'https://linkscripts.com/?a_aid=CleverRX'}
            target={'_blank'}
            rel={'noreferrer'}
            onClick={() => {
              !!gtag && gtag('event', 'linkscripts-navigation', {});
            }}
          >
            Get Started
          </a>
        </div>
        <img className={'glp1-hero-img'} src={'/static/images/linkscripts-hero.png'} alt={'GLP-1 Hero Image'} />
      </div>
    </div>
  );
}
