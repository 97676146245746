/* eslint-disable */
/* tslint:disable */
import {BaseClient, ControllerOptions} from './baseClient';
declare type ObjectId = string;

export class AnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async appOpen<TPromise = VoidResponse>(
    model: AppOpenRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/app-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logEvent<TPromise = VoidResponse>(
    model: LogEventRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logVoucherEvent<TPromise = VoidResponse>(
    model: LogVoucherEventRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/log-voucher-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async start<TPromise = VoidResponse>(
    model: TelemetryStartRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingResponse>(
    model: PriceRequest,
    handle: {
      200?: (result: PricingResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugDetails<TPromise = GetDrugDetailsResponse>(
    model: GetDrugDetailsRequest,
    handle: {
      200?: (result: GetDrugDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = GetDrugStructureResponse>(
    model: GetDrugStructureRequest,
    handle: {
      200?: (result: GetDrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponse>(
    model: SearchRequest,
    handle: {
      200?: (result: SearchResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async legacySearch<TPromise = LegacySearchResponse>(
    model: SearchRequest,
    handle: {
      200?: (result: LegacySearchResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/legacy-search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = HttpDrugResultV2>(
    model: GetDrugStructureV2Request,
    handle: {
      200?: (result: HttpDrugResultV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugInformation<TPromise = DrugInformationV2>(
    model: GetDrugInformationV2Request,
    handle: {
      200?: (result: DrugInformationV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-information?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponseV2>(
    model: SearchRequest,
    handle: {
      200?: (result: SearchResponseV2) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucher<TPromise = GetVoucherResponse>(
    model: GetVoucherRequest,
    handle: {
      200?: (result: GetVoucherResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucherSurvey<TPromise = GetVoucherSurveyResponse>(
    model: GetVoucherRequest,
    handle: {
      200?: (result: GetVoucherSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVoucherSurvey<TPromise = VoidResponse>(
    model: SubmitVoucherSurveyRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapForm<TPromise = VoidResponse>(
    model: SubmitPapRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-pap-form?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitPapFormV2<TPromise = VoidResponse>(
    model: SubmitPapRequestV2,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/submit-pap-form/v2?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class ShareClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async messagePrice<TPromise = VoidResponse>(
    model: MessagePriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async emailPrice<TPromise = VoidResponse>(
    model: EmailPriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/email-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageApp<TPromise = VoidResponse>(
    model: MessageAppRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitContactDetails<TPromise = VoidResponse>(
    model: SubmitContactDetailsRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/submit-contact-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageAppCustomized<TPromise = VoidResponse>(
    model: MessageAppCustomizedRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app-customized?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async shareCHFlyer<TPromise = VoidResponse>(
    model: ShareCHFlyerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/share-ch-flyer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async redirectToClhJoin<TPromise = VoidResponse>(
    model: RedirectToClhJoinRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/join/:urlSlug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'urlSlug')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':urlSlug', model.urlSlug).replace('{urlSlug}', model.urlSlug);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsSlugRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = LightJwtResponse>(
    model: RegisterRequest,
    handle: {
      200?: (result: LightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserResponse>(
    model: ValidateRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = UserResponse>(
    model: SetPushTokenRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserResponse>(
    model: SetPharmacyPreferencesRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserResponse>(
    model: GetUserRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      410: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 410) {
        await handle[410](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async watchDrug<TPromise = WatchDrugResponse>(
    model: WatchDrugRequest,
    handle: {
      200?: (result: WatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/watch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async geoPing<TPromise = VoidResponse>(
    model: RadarGeoRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/geo-ping?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async unwatchDrug<TPromise = UnwatchDrugResponse>(
    model: UnwatchDrugRequest,
    handle: {
      200?: (result: UnwatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/unwatch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UtilsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async voucherEvent<TPromise = VoidResponse>(
    model: WebVoucherLogEventRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getWebVoucherSurvey<TPromise = GetWebVoucherSurveyResponse>(
    model: GetWebVoucherSurveyRequest,
    handle: {
      200?: (result: GetWebVoucherSurveyResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebVoucherSurvey<TPromise = VoidResponse>(
    model: SubmitWebVoucherSurveyRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/submit-voucher-survey?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipcode<TPromise = GetZipcodeResponse>(
    model: GetZipcodeRequest,
    handle: {
      200?: (result: GetZipcodeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/zipcode?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerContact<TPromise = VoidResponse>(
    model: CreatePartnerContactRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLatLng<TPromise = GetLatLngResponse>(
    model: GetLatLngRequest,
    handle: {
      200?: (result: GetLatLngResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/latlng?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getKrogerBrand<TPromise = GetKrogerBrandResponse>(
    model: GetKrogerBrandRequest,
    handle: {
      200?: (result: GetKrogerBrandResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/kroger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: MetaResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/meta?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async partnerSlugRequest<TPromise = VoidResponse>(
    model: PartnerSlugRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-slug-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebContactForm<TPromise = VoidResponse>(
    model: WebContactFormRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebCalculatorAnalytics<TPromise = VoidResponse>(
    model: WebCalculatorRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    },
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/calculator?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex: any) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex: any) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export interface AppOpenRequest {
  firstTime: boolean;
  fromShare: string;
  fromLink: boolean;
}

export interface VoidResponse {}

export interface LogEventRequest {
  eventName: string;
  sessionId: string;
  userId?: string;
  meta?: string;
  isPharmacist?: boolean;
}

export interface LogVoucherEventRequest {
  eventName: AnalyticsType;
  sessionId: string;
  userId?: string;
  meta?: string;
  price?: DrugPriceV2;
  isPharmacist?: boolean;
}

export type AnalyticsType =
  | 'js-error'
  | 'bad-404'
  | 'native-error'
  | 'share-from-website'
  | 'share-from-website-health'
  | 'app-open'
  | 'app-first-open'
  | 'app-first-open-link'
  | 'app-first-open-share'
  | 'partner-slug-request'
  | 'search'
  | 'price'
  | 'price-no-filter'
  | 'price-no-filter-replace'
  | 'web-calculator'
  | 'listeners'
  | 'voucher'
  | 'x2ai-open'
  | 'x2ai-close'
  | 'loc-open'
  | 'loc-close'
  | 'share-savings'
  | 'view-cabinet-voucher'
  | 'view-voucher'
  | 'view-cabinet-more-prices'
  | 'open-survey-push'
  | 'pap-initial'
  | 'pap-submit'
  | 'pap-step'
  | 'pap-submission'
  | 'dialcare'
  | 'msk'
  | 'promo-code';

export interface DrugPriceV2 {
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;
  uncPrice: number;
  percentSavings: string;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export type PharmacyType =
  | 'Walgreens'
  | KrogerPharmacyType
  | 'Walmart'
  | 'CVS'
  | 'Costco'
  | 'Other';

export type KrogerPharmacyType =
  | 'Bakers'
  | 'City Market'
  | 'Dillons'
  | 'Food 4 Less'
  | 'Foods Co'
  | 'Fred Meyer'
  | "Fry's"
  | 'Gerbes'
  | 'Harris Teeter'
  | 'King Soopers'
  | 'Jay C Food Store'
  | 'Kroger'
  | 'Owens Market'
  | 'Pay-Less Super Markets'
  | 'QFC'
  | 'Ralphs'
  | 'Smiths';

export interface DrugCoupon {
  provider: 'rxedo' | 'singlecare' | 'scriptsave';
  retrieved: boolean;
  groupNumber: string;
  memberId: string;
  rxBin: string;
  pcn: string;

  pharmacistPhoneNumber: string;
  memberPhoneNumber: string;

  user?: string;
  password?: string;
}

export interface TelemetryStartRequest {
  sessionId: string;
  phoneDetails?: DBPhoneDetails;
}

export interface DBPhoneDetails {
  apiLevel?: number;
  brand?: string;
  model?: string;
  systemVersion?: string;
  systemName?: string;
  locale?: string;
  timezone?: string;
  lastAccess?: Date;
  error?: string;
  zipcode?: string;
  appVersion?: string;
}

export interface PriceRequest {
  ndc: string;
  zipcode?: string;
  quantity: string;
  forceBranded: boolean;
  pharmacy?: PharmacyPriceRequest;
  watchedDrugId?: string;
}

export interface PharmacyPriceRequest {
  address1: string;
  pharmacyType: PharmacyType;
  pharmacy: string;
  address2: string;
  postalCode: string;
  nabp: string;
}

export interface PricingResponse {
  watchedDrugId: string;
  pickedDrug: HttpPickedDrug;
  user: HttpUser;
  prices: DrugPrice[];
}

export interface HttpPickedDrug {
  zipcode: string;
  isGeneric: boolean;
  drugName: string;
  ndc: string;
  form: string;
  strengthItem: string;
  quantity: string;
  displayQuantity: string;
  forceBranded: boolean;
}

export interface HttpUser {
  id: string;
  watchedDrugs: HttpWatchedDrug[];
  createdDate: Date;
  brokerDetails: BrokerDetails;
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
  pushToken: {platform: string; token: string};
}

export interface HttpWatchedDrug {
  watchedDrugId: string;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  savedCoupon?: DrugPrice;
  dateSaved: Date;
  lastRefreshed: Date;
}

export interface DrugPrice {
  pickedDrug: HttpPickedDrug;
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export interface BrokerDetails {
  id: string;
  member_id: string;
  company_name: string;
  url_slug: string;
  share_url_slug?: string;
  group_number: string;
  isDefault: boolean;
  uniqueMemberRegistration: boolean;
  externalMemberId?: string;
  externalMemberIdSuffix?: string;
  requireDateOfBirth?: boolean;
  externalProvider: 'direct-care' | 'test';
}

export interface Pharmacy {
  address1: string;
  address2: string;
  city: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  state: string;
  distance: number;
  nabp: string;
  name: string;
  pharmacyType: PharmacyType;
}

export interface GetDrugDetailsRequest {
  ndc: string;
}

export interface GetDrugDetailsResponse {
  information: SinglecareMonoGraphData;
  faqs: SinglecareFAQ[];
  imageUrl: string;
}

export interface SinglecareMonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface SinglecareFAQ {
  IsActive: boolean;
  IsRequired: boolean;
  Question: SinglecareQuestion;
  QuestionId: number;
  QuestionSetId: number;
  QuestionSetQuestionId: number;
  SortOrder: number;
}

export interface SinglecareQuestion {
  Answers: SinglecareAnswer[];
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  Text: string;
}

export interface SinglecareAnswer {
  AnswerId: number;
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  SortOrder: number;
  Text: string;
}

export interface GetDrugStructureRequest {
  name: string;
}

export interface GetDrugStructureResponse {
  drugResult: HttpDrugResult | null;
}

export interface HttpDrugResult {
  brand: HttpDrug;
  generic: HttpDrug;
}

export interface HttpDrug {
  name: string;
  doseForms: HttpDoseForm[];
}

export interface HttpDoseForm {
  form: string;
  dispensables: HttpDispensable[];
}

export interface HttpDispensable {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantity[];
  ndcs: HttpNDC[];
}

export interface HttpDispensedQuantity {
  quantity: string;
  displayQuantity: string;
}

export interface HttpNDC {
  ndc: string;
  name: string;
  fullName: string;
  isGeneric: boolean;
}

export interface SearchRequest {
  query: string;
}

export interface SearchResponse {
  results: string[];
}

export interface LegacySearchResponse {
  results: {
    drug: {
      genericName: string;
      brandName: string;
      doseForms: {
        doseFormId: string;
        form: string;
        rank: number;
        dispensables: {
          dispensableId: string;
          strength: string;
          strengthUnitOfMeasure: string;
          strengthRank: number;
          quantities: {
            quantity: string;
          }[];
          ndcs: {
            ndc: string;
            drugName: string;
            rank: number;
          }[];
        }[];
      }[];
    };
    doseFormId: string;
    dispensableId: string;
    ndc: string;
  }[];
}

export interface PriceV2Request {
  ndc: string;
  zipcode?: string;
  quantity: number;
  pharmacy?: PharmacyPriceRequest;
  platform?: 'clx-web' | 'clh-web' | 'clx-app' | 'clx-iframe' | 'portal';
}

export interface PricingV2Response {
  prices: DrugPriceV2[];
  papEligibility?: {isEligible: boolean; papPrice: string};
}

export interface GetDrugStructureV2Request {
  seoName: string;
}

export interface HttpDrugResultV2 {
  brands: HttpDrugV2[];
  generics: HttpDrugV2[];
}

export interface HttpDrugV2 {
  name: string;
  doseForms: HttpDoseFormV2[];
}

export interface HttpDoseFormV2 {
  form: string;
  dispensables: HttpDispensableV2[];
}

export interface HttpDispensableV2 {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantityV2[];
}

export interface HttpDispensedQuantityV2 {
  quantity: number;
  ndc: HttpNDCV2;
}

export interface HttpNDCV2 {
  displayQuantity: number;
  dosage: string;
  form: string;
  fullName: string;
  gpi: string;
  inactive: boolean;
  isGeneric: boolean;
  marketingForm: string;
  marketingName: string;
  marketingSEOName: string;
  ndc: string;
  name: string;
  numScripts: number;
  packageQuantity: number;
  packageSize: number;
  packageSizeUOM: string;
  priceUsualAndCustomary: number;
  quantity: number;
  repack: boolean;
  rxNorm: string;
  seoName: string;
  strength: number;
  strengthString: string;
  strengthUnitOfMeasure: string;
}

export interface GetDrugInformationV2Request {
  ndc: string;
}

export interface DrugInformationV2 {
  Drug: DrugInformationV2Drug;
  FAQs: any[];
  ImageUrl: string;
  MonoGraphData: DrugInformationV2MonoGraphData;
  RelatedDrugs: DrugInformationV2RelatedDrug[];
}

export interface DrugInformationV2Drug {
  CanonicalURL: string;
  DeaClassCode: string;
  Description: string;
  DisplayQuantity: number;
  Dosage: string;
  Form: string;
  FullName: string;
  GPI: string;
  Inactive: boolean;
  IsGeneric: boolean;
  MarketingForm: string;
  MarketingName: string;
  MarketingSEOName: string;
  MetaDescription: string;
  MetaTitle: string;
  NDC: string;
  Name: string;
  NumScripts: number;
  PackageQuantity: number;
  PackageSize: number;
  PackageSizeUOM: string;
  PriceUsualAndCustomary: number;
  Quantity: number;
  Repack: boolean;
  RxNorm: string;
  SEOName: string;
  SEONoIndex: boolean;
  Strength: number;
  StrengthString: string;
  StrengthUnitOfMeasure: string;
  TopPrescriptionSortOrder: number;
  Treatment: string;
  UnitDoseUnitUsePkgCode: string;
}

export interface DrugInformationV2MonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface DrugInformationV2RelatedDrug {
  DrugName: string;
  SEOName: string;
}

export interface SearchResponseV2 {
  results: SinglecareDrugSearchResult[];
}

export interface SinglecareDrugSearchResult {
  score: number;
  display_name: string;
  id: string;
  seo_name: string;
  ndc: string;
}

export interface GetVoucherRequest {
  price: DrugPriceV2;
}

export interface GetVoucherResponse {
  price: DrugPriceV2;
}

export interface GetVoucherSurveyResponse {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason: string;
  fullySubmitted: boolean;
}

export interface SubmitVoucherSurveyRequest {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason?: string;
  platform: 'clx-app' | 'clh-app';
  price: DrugPriceV2;
}

export interface SubmitPapRequest {
  platform: AnalyticsPlatform;
  ndc: string;
  formData: RxValetPapFormData;
  url?: string;
}

export type AnalyticsPlatform =
  | 'clx-web'
  | 'clh-web'
  | 'clx-app'
  | 'clx-iframe'
  | 'portal'
  | 'clh-app';

export type RxValetPapFormData = {
  annualIndividualIncome: string;
  firstName: string;
  lastName: string;
  agreeToTerms: boolean;
  bestTimeToCall: string;
  contactNumber: string;
  email: string;
  householdMembers: string;
  physicianCity: string;
  physicianName: string;
  totalHouseholdIncome: string;
  physicianPhone: string;
  physicianState: string;
};

export interface SubmitPapRequestV2 {
  platform: AnalyticsPlatform;
  ndc: string;
  formData: RxValetPapFormDataV2;
  url?: string;
}

export interface RxValetPapFormDataV2 extends RxValetPapFormData {
  memberAddress1: string;
  memberAddress2: string;
  memberCity: string;
  memberState: string;
  memberZip: string;
  dateOfBirth: string; // MM-DD-YYYY
}

export interface MessagePriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  phoneNumber?: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare' | 'scriptsave';
  drugNameSubText?: string;
}

export interface EmailPriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  email: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare' | 'scriptsave';
}

export interface MessageAppRequest {
  urlSlug: string;
  phoneNumber: string;
  health?: boolean;
}

export interface SubmitContactDetailsRequest {
  name: string;
  phone: string;
  companyName: string;
  email: string;
}

export interface MessageAppCustomizedRequest {
  senderName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  urlSlug: string;
  messageOption: '0' | '1' | '2' | '3';
}

export interface ShareCHFlyerRequest {
  flyers: {
    employer: boolean;
    voluntary: boolean;
    overview: boolean;
  };
  sender: {name: string; email: string};
  recipients: {name: string; email: string}[];
  qrCodeBase64?: string;
  companyNameBase64?: string;
}

export interface RedirectToClhJoinRequest {
  urlSlug: string;
}

export interface GetBrokerDetailsRequest {
  groupCode: string;
  memberId: string;
  dateOfBirth?: string;
}

export interface GetBrokerDetailsResponse {
  brokerDetails: BrokerDetails;
}

export interface GetBrokerDetailsSlugRequest {
  slug: string;
}

export interface RegisterRequest {
  phoneNumber: string;
  isLogin: boolean;
  fromShare?: boolean;
  brokerDetails: BrokerDetails;
  phoneDetails: DBPhoneDetails;
}

export interface LightJwtResponse {
  jwt: string;
}

export interface ValidateRequest {
  activationCode: string;
}

export interface JwtGetUserResponse extends UserResponse {
  jwt: string;
}

export interface UserResponse {
  user: HttpUser;
}

export interface SetPushTokenRequest {
  token: string;
  platform: string;
}

export interface SetPharmacyPreferencesRequest {
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
}

export interface GetUserRequest {}

export interface WatchDrugRequest {
  watchedDrugId: string | null;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  saveCoupon: DrugPrice;
}

export interface WatchDrugResponse extends UserResponse {
  watchedDrugId: string;
}

export interface RadarGeoRequest {
  events: GeoEvent[];
  user: GeoUser;
}

export interface GeoEvent {
  _id: string;
  createdAt: string;
  live: boolean;
  type: string;
  user: GeoUserSmall;
  geofence?: Geofence;
  location: GeoLocation;
  locationAccuracy: number;
  confidence: number;
  place?: GeoPlace;
}

export interface GeoUserSmall {
  _id: string;
  userId: string;
  description: string;
  metadata: GeoMetadata;
  deviceId?: string;
}

export interface GeoMetadata {
  session: string;
}

export interface Geofence {
  _id: string;
  tag: string;
  externalId: string;
  description: string;
}

export interface GeoLocation {
  type: string;
  coordinates: number[];
}

export interface GeoPlace {
  _id: string;
  name: string;
  chain: Chain;
  location: GeoLocation;
  categories: string[];
}

export interface Chain {
  name: string;
  slug: string;
  domain: string;
}

export interface GeoUser {
  _id: string;
  live: boolean;
  userId: string;
  deviceId: string;
  description: string;
  metadata: GeoMetadata;
  updatedAt: string;
  createdAt: string;
  location: GeoLocation;
  locationAccuracy: number;
  stopped: boolean;
  foreground: boolean;
  deviceType: string;
  ip: string;
  geofences: Geofence[];
  place: GeoPlace;
  insights: GeoInsights;
}

export interface GeoInsights {
  state: GeoState;
}

export interface GeoState {
  home: boolean;
  office: boolean;
  traveling: boolean;
}

export interface UnwatchDrugRequest {
  watchedDrugId: string;
}

export interface UnwatchDrugResponse extends UserResponse {}

export interface WebVoucherLogEventRequest {
  sessionId: string;
  price?: string;
  pharmacyName?: string;
  zipcode?: string;
  ndc?: string;
  drugName?: string;
  groupCode?: string;
  memberId?: string;
  type: WebLogAnalyticsType;
  email?: string;
  phone?: string;
  quantity?: string;
}

export type WebLogAnalyticsType =
  | 'initial'
  | 'price'
  | 'load-voucher'
  | 'text-voucher'
  | 'email-voucher';

export interface GetWebVoucherSurveyRequest {
  webUserId?: string;
  price: DrugPriceV2;
}

export interface GetWebVoucherSurveyResponse {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason: string;
  fullySubmitted: boolean;
}

export interface SubmitWebVoucherSurveyRequest {
  willUse: -1 | 0 | 1;
  goodPrice: -1 | 0 | 1;
  wasUsed: -1 | 0 | 1;
  notUsedReason?: string;
  platform: 'clx-web';
  price: DrugPriceV2;
  webUserId: string;
}

export interface GetZipcodeRequest {
  lat: string;
  lng: string;
}

export interface GetZipcodeResponse {
  zipcode: string;
}

export interface CreatePartnerContactRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  referredBy: string;
  partnerType: string;
}

export interface GetLatLngRequest {
  zipcode: string;
}

export interface GetLatLngResponse {
  lat: string;
  lng: string;
}

export interface GetKrogerBrandRequest {
  zipcode: string;
}

export interface GetKrogerBrandResponse {
  krogerPharmacyType: KrogerPharmacyType | null;
}

export interface VoidRequest {}

export interface MetaResponse {
  pushModels: PushModels;
}

export type PushModels =
  | {
      type: 'pharmacy-push';
      pharmacy: Pharmacy;
    }
  | {
      type: 'text';
    }
  | {
      type: 'visit';
      visitId: string;
      dependentId: string;
    }
  | {
      type: 'openLink';
      url: string;
    };

export interface PartnerSlugRequest {
  partnerId: string;
  isShare: string;
}

export interface WebContactFormRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  contactTypes: string[];
  message: string;
}

export interface WebCalculatorRequest {
  userId: string;
  step: string;
  value: string;
}
